import React, { useEffect, useState } from "react";
import Application from "components/Cohort/Application";
import Helmet from "react-helmet";

import { auth } from "api/firebase";
import SignUp from "components/Auth/SignUp";

import {
  RegistrationHeader,
  Button,
  Pricing,
  RegistrationPricing
} from "components/Cohort/styles";
import { fetchUserData } from "../../../api/db";
import { navigate } from "gatsby";

const apply = () => {
  const [username, setUsername] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const user = urlParams.get("user");
    if (user && user != "") {
      fetchUserData(user).once("value", snapshot => {
        let userData = snapshot.val();
        if (userData && userData != undefined) {
          userData = Object.values(userData);
          if (
            userData[0].tags.includes("cohort 2") ||
            userData[0].tags.includes("team") ||
            userData[0].tags.includes("mentor")
          ) {
            setUsername(userData[0].username);
          } else {
            alert("Your referrer was not in a cohort");
            navigate("/cohort");
          }
        } else {
          alert("Your referrer does not exist.");
          navigate("/cohort");
        }
      });
    } else {
      navigate("/cohort");
    }

    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setData(userData);
  }, []);
  return (
    <>
      <Helmet
        title="Cohort Registration | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "og:title",
            content: "Cohort | Enlight"
          },
          {
            name: "og:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          { name: "og:url", content: "https://enlight.nyc/cohort" },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          },
          {
            name: "twitter:title",
            content: "Cohort | Enlight"
          },
          {
            name: "twitter:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          }
        ]}
      />

      {username && username != "" && (
        <>
          <RegistrationHeader>
            <main>
              <div className="titles">
                <div>
                  <h1 className="referral-header">
                    You've been invited by {username}.
                  </h1>
                  <h2>
                    Learn to code in just two weeks by building <i>real</i>{" "}
                    projects.
                  </h2>
                  <br /> <br />
                </div>
              </div>

              <RegistrationPricing>
                <Pricing>
                  <br />
                  <div>
                    <h2>Enlight Learner (Referral)</h2>
                    <h4>Cohort #3: August 13th - August 27th</h4>

                    <p className="price">
                      {" "}
                      <span className="compare">$80</span>{" "}
                      <span className="cost">$35</span>{" "}
                      <span className="tag">save 56.2%</span>
                    </p>

                    <ul>
                      <li>
                        Build 4 <i>real</i> projects
                      </li>
                      <li>10 project-based webinars</li>
                      <li>Exclusive community & forum</li>
                      <li>Project recommendations</li>
                      <li>Certificate of completion</li>
                    </ul>
                    <br />
                    <i>
                      Referral discount special offer valid till August 13th.
                      Limited spots — act soon.
                    </i>
                  </div>
                </Pricing>
              </RegistrationPricing>
            </main>
          </RegistrationHeader>

          {data ? null : (
            // <Application type="referral" userData={data} referral={username} />
            <>
              <h2
                style={{
                  textAlign: "center",
                  color: "#333",
                  marginTop: "32px"
                }}
              >
                Please sign up to apply to Enlight Cohorts.
              </h2>
              <SignUp text={false} cohort={true} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default apply;
